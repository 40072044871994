<script setup>
const props = defineProps(["book", "active"]);
const emit = defineEmits(["select"]);
</script>
<template>
  <div
    class="chip-panel-item"
    :class="{ active: active }"
    @click="emit('select', book.bookCode)"
  >
    {{ book.bookCodeOrig }} ({{ book.amount }})
  </div>
</template>
<style scope>
.chip-panel-item {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  border-radius: 8px;
  border: 1.5px solid #bbc2d4;
  font-size: 12px;
  padding: 0 8px;
  cursor: pointer;
  /* display: flex; */
  /* align-items: center; */
}
.chip-panel-item:hover {
  cursor: pointer;
  border: 1.5px solid var(--root-green);
}
.chip-panel-item:active {
  color: white;
  background-color: var(--root-green);
}
.chip-panel-item.active {
  color: var(--root-green);
  border: 1.5px solid var(--root-green);
  background-color: #effdfb;
}
</style>
